import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyColumn = lazy(
  () => import("./column-DmY4tyZ_.js").then((module) => ({
    default: module.Column
  }))
);
function Column({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyColumn, { ...props });
}
const useColumnContent = () => {
  return useMemo(
    () => ({
      column: {
        component: Column
      }
    }),
    []
  );
};
export {
  LazyColumn as Column,
  useColumnContent
};
